const menuIT = {
    "pizza":[
        {
            "id": "1",
            "nome": "Marinara",
            "ingredienti": "Pomodoro, Aglio",
            "prezzo": 3.80,
            "allergie": [1]
        },
        {
            "id": "2",
            "nome": "Margherita",
            "ingredienti": "Pomodoro, Mozzarella",
            "prezzo": 5.50,
            "allergie": [1,7]
        },
		{
            "id": "3",
            "nome": "Funghi",
            "ingredienti": "Pomodoro, Mozzarella, Funghi",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "4",
            "nome": "Prosciutto",
            "ingredienti": "Pomodoro, Mozzarella, Prosciutto",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "5",
            "nome": "Prosciutto Funghi",
            "ingredienti": "Pomodoro, Mozzarella, Prosciutto, Funghi",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "6",
            "nome": "Capricciosa",
            "ingredienti": "Pomodoro, Mozzarella, Funghi, Carciofi",
            "prezzo": 8.50,
            "allergie": [1,7]
        },
		{
            "id": "7",
            "nome": "Quattro Stagioni",
            "ingredienti": "Pomodoro, Mozzarella, Funghi, Carciofi",
            "prezzo": 8.50,
            "allergie": [1,7]
        },
		{
            "id": "8",
            "nome": "Pugliese",
            "ingredienti": "Pomodoro, Mozzarella, Cipolla",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "9",
            "nome": "Salamino Piccante",
            "ingredienti": "Pomodoro, Mozzarella, Salamino Piccante",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "10",
            "nome": "Romana",
            "ingredienti": "Pomodoro, Mozzarella, Acciughe",
            "prezzo": 7.00,
            "allergie": [1,4,7]
        },
		{
            "id": "11",
            "nome": "Napoletana",
            "ingredienti": "Pomodoro, Mozzarella, Acciughe, Capperi",
            "prezzo": 8.50,
            "allergie": [1,4,7]
        },
		{
            "id": "12",
            "nome": "Siciliana",
            "ingredienti": "Pomodoro, Mozzarella, Acciughe, Capperi, Salamino, Olive Verdi",
            "prezzo": 8.50,
            "allergie": [1,4,7]
        },
		{
            "id": "13",
            "nome": "Wiennese",
            "ingredienti": "Pomodoro, Mozzarella, Wurstel",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "14",
            "nome": "Tonno e Cipolla",
            "ingredienti": "Pomodoro, Mozzarella, Tonno, Cipolla",
            "prezzo": 8.00,
            "allergie": [1,4,7]
        },
		{
            "id": "15",
            "nome": "Zingara",
            "ingredienti": "Pomodoro, Mozzarella, Peperoni Dolci",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "16",
            "nome": "Calzone",
            "ingredienti": "Pomodoro, Mozzarella, Prosciutto, Funghi",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "17",
            "nome": "Calzone Filante",
            "ingredienti": "Pomodoro, Mozzarella, Gorgonzola piccante, Formaggio vecchio",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "18",
            "nome": "Cynar",
            "ingredienti": "Pomodoro, Mozzarella, Carciofi",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "19",
            "nome": "Pizza della Casa",
            "ingredienti": "Pomodoro, Mozzarella, Prosciutto, Funghi, Carciofini,Salamino Piccante, Wurstel, Olive Verdi, Uovo",
            "prezzo": 9.50,
            "allergie": [1,3,7]
        },
		{
            "id": "20",
            "nome": "Quattro Formaggi",
            "ingredienti": "Pomodoro, Mozzarella, Grana, Gorgonzola Piccante",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "21",
            "nome": "Diavola",
            "ingredienti": "Pomodoro, Mozzarella, Salamino Piccante, Peperoni in semi",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "22",
            "nome": "Verdure",
            "ingredienti": "Pomodoro, Mozzarella, Asparagi, Funghi, Melanzane, Peperoni, Carciofi, Zucchine, Spinaci",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "23",
            "nome": "Alla Francese",
            "ingredienti": "Pomodoro, Mozzarella, Brie",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "24",
            "nome": "Delicata",
            "ingredienti": "Pomodoro, Mozzarella, Brie, Rucola",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "25",
            "nome": "Sfiziosa",
            "ingredienti": "Pomodoro, Mozzarella, Brie, Speck",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "26",
            "nome": "Estiva",
            "ingredienti": "Pomodoro, Mozzarella, Rucola, Mais",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "27",
            "nome": "Saporita",
            "ingredienti": "Pomodoro, Mozzarella, Patate lesse, Salsiccia",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "28",
            "nome": "Lory",
            "ingredienti": "Pomodoro, Mozzarella, Salsiccia, Pomodorini",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "29",
            "nome": "Mattia",
            "ingredienti": "Pomodoro, Mozzarella, Salamino Piccante, Salsiccia",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "30",
            "nome": "Bismarck",
            "ingredienti": "Pomodoro, Mozzarella, Asparagi, Uovo",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "31",
            "nome": "Spinaci e Asparagi",
            "ingredienti": "Pomodoro, Mozzarella, Spinaci, Asparagi",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "32",
            "nome": "Spinaci",
            "ingredienti": "Pomodoro, Mozzarella, Spinaci",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "33",
            "nome": "Asparagi",
            "ingredienti": "Pomodoro, Mozzarella, Asparagi",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "34",
            "nome": "Melanzane",
            "ingredienti": "Pomodoro, Mozzarella, Melanzane",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "35",
            "nome": "Tonno e Olive",
            "ingredienti": "Pomodoro, Mozzarella, Tonno, Olive",
            "prezzo": 8.00,
            "allergie": [1,4,7]
        },
		{
            "id": "36",
            "nome": "Tonno e Peperoni",
            "ingredienti": "Pomodoro, Mozzarella, Tonno, Peperoni",
            "prezzo": 8.00,
            "allergie": [1,4,7]
        },
		{
            "id": "37",
            "nome": "Baga",
            "ingredienti": "Pomodoro, Mozzarella, Gorgonzola Piccante, Radicchio",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "38",
            "nome": "Mirna",
            "ingredienti": "Pomodoro, Mozzarella, Rucola, Grana, Pomodorini",
            "prezzo": 8.50,
            "allergie": [1,7]
        },
		{
            "id": "39",
            "nome": "Capitan Hook",
            "ingredienti": "Pomodoro, Mozzarella, Tonno, Cipolla, Salamino Piccante",
            "prezzo": 9.00,
            "allergie": [1,4,7]
        },
		{
            "id": "40",
            "nome": "Pizza del Bosco",
            "ingredienti": "Pomodoro, Mozzarella, Funghi misti",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "41",
            "nome": "Stark",
            "ingredienti": "Pomodoro, Formaggio vecchio",
            "prezzo": 7.50,
            "allergie": [1,7]
        },
		{
            "id": "42",
            "nome": "Suprema",
            "ingredienti": "Pomodoro, Mozzarella, Rucola, Grana, Bresaola",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "43",
            "nome": "Pizza Letizia",
            "ingredienti": "Pomodoro, Mozzarella, Funghi Porcini, Brie, Speck, Noccioline",
            "prezzo": 9.00,
            "allergie": [1,7,8]
        },
		{
            "id": "44",
            "nome": "Far West",
            "ingredienti": "Pomodoro, Mozzarella, Fagioli, Cipolla, Pancetta",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "45",
            "nome": "Kaiser",
            "ingredienti": "Pomodoro, Mozzarella, Salamino piccante, Wurstel, Capperi, Grana",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "46",
            "nome": "Memo",
            "ingredienti": "Pomodoro, Mozzarella, Piselli, Tonno",
            "prezzo": 8.00,
            "allergie": [1,4,7]
        },
		{
            "id": "47",
            "nome": "Tirolese",
            "ingredienti": "Pomodoro, Mozzarella, Wurstel, Panna, Speck",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "48",
            "nome": "Rustica",
            "ingredienti": "Pomodoro, Mozzarella, Fagioli, Cipolla, Salsiccia",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "49",
            "nome": "Kostlich",
            "ingredienti": "Pomodoro, Mozzarella, Gorgonzola piccante, Porcini",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "50",
            "nome": "Porcheria",
            "ingredienti": "Pomodoro, Mozzarella, Salamino Piccante, Salsiccia, Pancetta",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "51",
            "nome": "Micky",
            "ingredienti": "Pomodoro, Mozzarella, Asparagi, Salamino piccante, Gorgonzola piccante",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "52",
            "nome": "Broccoli",
            "ingredienti": "Pomodoro, Mozzarella, Salsiccia, Broccoli, Ricotta affumicata",
            "prezzo": 9.00,
            "allergie": [1,7],
            "print": "newPage"
        },
		{
            "id": "53",
            "nome": "Fiamma",
            "ingredienti": "Pomodoro, Mozzarella, Zucchine, Curry, Gamberetti",
            "prezzo": 9.00,
            "allergie": [1,2,4,7]
        },
		{
            "id": "54",
            "nome": "Autunno",
            "ingredienti": "Pomodoro, Mozzarella, Castagne, Salsicia, Ricotta affumicata",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "55",
            "nome": "Illuminata",
            "ingredienti": "Pomodoro, Mozzarella, Mascarpone, Salamino piccante",
            "prezzo": 8.50,
            "allergie": [1,7]
        },
		{
            "id": "56",
            "nome": "Hawaii",
            "ingredienti": "Pomodoro, Mozzarella, Ananas, Speck",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "57",
            "nome": "Edelweiss",
            "ingredienti": "Pomodoro, Mozzarella, Salsiccia, Ricotta affumicata",
            "prezzo": 8.00,
            "allergie": [1,7],
			"novita": true 
        },
		{
            "id": "58",
            "nome": "Salzach",
            "ingredienti": "Pomodoro, Mozzarella, Brie, Pomodorini, Porri",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "59",
            "nome": "Notti Magiche",
            "ingredienti": "Pomodoro, Mozzarella, Filetto affumicato, Valeriana, Scaglie di Grana",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "60",
            "nome": "Fiori di Zucca",
            "ingredienti": "Pomodoro, Mozzarella, Brie, Fiori di Zucca",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "61",
            "nome": "Halloween",
            "ingredienti": "Pomodoro, Mozzarella, Zucca, Salsiccia, Ricotta affumicata",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "67",
            "nome": "Pizza Thomas",
            "ingredienti": "Pomodoro, Mozzarella, Scamorza, Patate, Salsiccia, Rosmarino",
            "prezzo": 9.00,
            "allergie": [1,7],
			"novita": true
        },
		{
            "id": "94",
            "nome": "Fossati Kyle",
            "ingredienti": "Pomodoro, Mozzarella, Grana, Gorgonzola piccante, Salsiccia, Ricotta affumicata",
            "prezzo": 10.00,
            "allergie": [1,7]
        },
		{
            "id": "95",
            "nome": "Queen Mama",
            "ingredienti": "Pomodoro, Mozzarella, Grana Padano, Peperoni, Patate",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "96",
            "nome": "Joker",
            "ingredienti": "Mozzarella, Gorgonzola, Noci",
            "prezzo": 8.50,
            "allergie": [1,5,7]
        },
		{
            "id": "97",
            "nome": "Freddie",
            "ingredienti": "Pomodoro, Mozzarella, Salmone Affumicato, Porro",
            "prezzo": 8.50,
            "allergie": [1,4,7]
        },
		{
            "id": "98",
            "nome": "Zlatah",
            "ingredienti": "Mozzarella, Zucchine, Salmone Affumicato, Panna",
            "prezzo": 9.00,
            "allergie": [1,4,7]
        }
    ],
    "dessert":[
        {
            "id": "62",
            "nome": "Panna Cotta",
            "ingredienti": "Con salsa di fragole, caramello o kiwi",
            "prezzo": 4.00,
            "allergie": [1,3,5,6,7,8]
        },
		{
            "id": "63",
            "nome": "Tartufi",
            "ingredienti": "Con Caffè o Cioccolato",
            "prezzo": 4.00,
            "allergie": [1,3,5,6,7,8]
        },
		{
            "id": "64",
            "nome": "Tartufo Affogato",
            "ingredienti": "Con Caffè o Cioccolato",
            "prezzo": 5.00,
            "allergie": [1,3,5,6,7,8]
        },
		{
            "id": "65",
            "nome": "Semifreddi",
            "ingredienti": "-",
            "prezzo": 4.00,
            "allergie": [1,3,5,6,7,8]
        },
		{
            "id": "66",
            "nome": "Meringata",
            "ingredienti": "-",
            "prezzo": 4.00,
            "allergie": [1,3,5,6,7,8]
        }
    ],
	"caffeeliquori":[
        {
            "id": "68",
            "nome": "Caffè",
            "ingredienti": "",
            "prezzo": 1.30,
            "allergie": []
        },
		{
            "id": "69",
            "nome": "Caffè Corretto",
            "ingredienti": "",
            "prezzo": 1.80,
            "allergie": []
        },
		{
            "id": "70",
            "nome": "Liquori nazionali",
            "ingredienti": "",
            "prezzo": 3.50,
            "allergie": []
        },
		{
            "id": "71",
            "nome": "Grappe aromatiche",
            "ingredienti": "",
            "prezzo": 3.50,
            "allergie": []
        },
		{
            "id": "72",
            "nome": "Grappa Prime Uve Nera",
            "ingredienti": "",
            "prezzo": 5.00,
            "allergie": []
        },
		{
            "id": "73",
            "nome": "Liquori esteri e Whisky",
            "ingredienti": "",
            "prezzo": "5.00 - 8.00",
            "allergie": []
        },
		{
            "id": "74",
            "nome": "Irish Caffè",
            "ingredienti": "",
            "prezzo": 6.00,
            "allergie": []
        },
		{
            "id": "75",
            "nome": "Sgroppino",
            "ingredienti": "",
            "prezzo": 5.50,
            "allergie": []
        }
    ],
	"bibite":[
        {
            "id": "76",
            "nome": "Fanta - CocaCola - Sprite",
            "ingredienti": "",
            "prezzo": 2.50,
            "allergie": []
        },
		{
            "id": "78",
            "nome": "The Freddo",
            "ingredienti": "Pesca o Limone",
            "prezzo": 2.50,
            "allergie": []
        },
		{
            "id": "79",
            "nome": "Succhi di Frutta",
            "ingredienti": "Pera, Pesca, Albicocca, Ace, Arancia, Ananas",
            "prezzo": 2.50,
            "allergie": []
        },
		{
            "id": "80",
            "nome": "Acqua minerale",
            "ingredienti": "Naturale o Frizzante",
            "prezzo": 1.50,
            "allergie": []
        }
	],
	"aperitivi":[
        {
            "id": "81",
            "nome": "Gingerino, SanBitter, Campari",
            "ingredienti": "",
            "prezzo": 2.50,
            "allergie": []
        },
		{
            "id": "82",
            "nome": "Furlan",
            "ingredienti": "",
            "prezzo": 2.50,
            "allergie": []
        },
		{
            "id": "83",
            "nome": "Prosecco",
            "ingredienti": "Al calice",
            "prezzo": 2.50,
            "allergie": []
        }
	],
	"birreevini":[
        {
            "id": "84",
            "nome": "Birra Piccola",
            "ingredienti": "",
            "prezzo": 2.50,
            "allergie": []
        },
		{
            "id": "85",
            "nome": "Birra Media",
            "ingredienti": "",
            "prezzo": 4.50,
            "allergie": []
        },
		{
            "id": "86",
            "nome": "Stivale o Caraffa di Birra",
            "ingredienti": "",
            "prezzo": 15.00,
            "allergie": []
        },
		{
            "id": "87",
            "nome": "Tocai del Collio",
            "ingredienti": "1/4 lt",
            "prezzo": 4.00,
            "allergie": []
        },
		{
            "id": "88",
            "nome": "Tocai del Collio",
            "ingredienti": "1/2 lt",
            "prezzo": 5.00,
            "allergie": []
        },
		{
            "id": "89",
            "nome": "Tocai del Collio",
            "ingredienti": "Bottiglia",
            "prezzo": 8.50,
            "allergie": []
        },
		{
            "id": "90",
            "nome": "Merlot",
            "ingredienti": "1/4 lt",
            "prezzo": 4.00,
            "allergie": []
        },
		{
            "id": "91",
            "nome": "Merlot",
            "ingredienti": "1/2 lt",
            "prezzo": 5.00,
            "allergie": []
        },
		{
            "id": "92",
            "nome": "Merlot",
            "ingredienti": "Bottiglia",
            "prezzo": 8.50,
            "allergie": []
        },
		{
            "id": "93",
            "nome": "Prosecco",
            "ingredienti": "Bottiglia",
            "prezzo": 15.00,
            "allergie": []
        }
	]
};

export default menuIT;