const en = {};
en.cerca = 'Search...';
en.lang = 'IT';
en.pizze = 'Pizze';
en.dessert = "Dessert";
en.caffeeliquori = "Caffè & Liquori";
en.bibite = "Drinks";
en.aperitivi = "Appetizers";
en.birreevini = "Beers & Wines";
en.birreeviniAttaccato = "Beers&Wines";
en.caffeeliquoriAttaccato = "Caffè&Liquori";
en.infoAllergie = [
    "Cereals containing gluten and derivatives (wheat, rye, barley, oats, spelled, kamut)",
    "Crustaceans and products based on crustaceans",
    "Eggs and Egg products",
    "Fish and Fish products",
    "Peanuts and peanut-based products",
    "Soy and soy products",
    "Milk and milk products",
    "Nuts and their products (almonds, hazelnuts, walnuts, cashew nuts, peccai, from Brazil, pistachios, macadamia nuts)",
    "Celery and celery-based products",
    "Mustard and mustard-based products",
    "Sesame seeds and sesame products",
    "Sulphites in concentrations above than 10mg / Kg",
    "Lupins and products-based on lupins",
    "Molluscs and products based on molluscs"
];
en.allergeni = "Allergens";
en.chiudi = "Close";
en.ingredienti = "Ingredients";
en.info = "Info";
en.cliccasuitem = "Tap an element for more info.";
en.textInfoAllergeni = "Information about allergens or their derivatives";
en.textLeggeAllergeni = 'List of allergenic ingredients used in this exercise and present in Annex II of EU Reg. No. 1169/2011 - "Substances or products that cause allergies or intolerances"';
en.textResponsabileAllergeni = "One of our responsible staff is at your disposal to provide any additional support or information, including trough the displaying appropriate documentation, recipe books, original raw material labels.";
en.closingDay = "Closing Days: Monday and Tuesday"

export default en;