import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import uuidv1 from  'uuid/v1';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import uno from "./img/1.png";
import due from "./img/2.png";
import tre from "./img/3.png";
import quattro from "./img/4.png";
import cinque from "./img/5.png";
import sei from "./img/6.png";
import sette from "./img/7.png";
import otto from "./img/8.png";
import nove from "./img/9.png";
import dieci from "./img/10.png";
import undici from "./img/11.png";
import dodici from "./img/12.png";
import tredici from "./img/13.png";
import quattordici from "./img/14.png";
import './App.css';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  allergeni:{
    height: "0px",
    overflow: "hidden",
    padding: "0px",
    display: "block"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ItemMenu(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  function handleOpen() {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.grow} data-print={props.print}>
      <ListItem key={props.id} onClick={() => handleOpen()}>
        <ListItemText primary={props.nome} secondary={props.ingredienti} />
        <ListItemSecondaryAction>
          {isNaN(props.prezzo) ? props.prezzo : props.prezzo.toFixed(2)} €<br/>
          {props.allergie.map((allergia) => {
            var icon = uno;
            if(allergia === 2){
              icon = due;
            } else if(allergia === 3){
              icon = tre;
            } else if(allergia === 4){
              icon = quattro;
            } else if(allergia === 5){
              icon = cinque;
            } else if(allergia === 6){
              icon = sei;
            } else if(allergia === 7){
              icon = sette;
            } else if(allergia === 8){
              icon = otto;
            } else if(allergia === 9){
              icon = nove;
            } else if(allergia === 10){
              icon = dieci;
            } else if(allergia === 11){
              icon = undici;
            } else if(allergia === 12){
              icon = dodici;
            } else if(allergia === 13){
              icon = tredici;
            } else if(allergia === 14){
              icon = quattordici;
            }
            return(<img className="iconAllerg" src={icon} alt={props.lang.infoAllergie[(allergia-1)]} key={uuidv1()}/>)})
          }
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="middle" component="div" />

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
      >
        <DialogContent>
          <Typography variant="h4">{props.nome}</Typography>
          {props.ingredienti && props.ingredienti !== "" ? 
            <div>
              <Typography variant="h5">{props.infoTitle}</Typography>
              <p>{props.ingredienti}</p>
            </div>
            : "" }
          {props.allergie && props.allergie.length > 0 ? 
          <div>
            <Typography variant="h5">{props.lang.allergeni}</Typography>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  {props.allergie.map((allergia) => {
                    var icon = uno;
                    if(allergia === 2){
                      icon = due;
                    } else if(allergia === 3){
                      icon = tre;
                    } else if(allergia === 4){
                      icon = quattro;
                    } else if(allergia === 5){
                      icon = cinque;
                    } else if(allergia === 6){
                      icon = sei;
                    } else if(allergia === 7){
                      icon = sette;
                    } else if(allergia === 8){
                      icon = otto;
                    } else if(allergia === 9){
                      icon = nove;
                    } else if(allergia === 10){
                      icon = dieci;
                    } else if(allergia === 11){
                      icon = undici;
                    } else if(allergia === 12){
                      icon = dodici;
                    } else if(allergia === 13){
                      icon = tredici;
                    } else if(allergia === 14){
                      icon = quattordici;
                    }
                    return(<TableRow key={allergia}>
                      <TableCell align="right"><img className="iconAllerg" src={icon} alt={props.lang.infoAllergie[(allergia-1)]}/></TableCell>
                      <TableCell align="left">{props.lang.infoAllergie[(allergia-1)]}</TableCell>
                    </TableRow>)
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          : "" }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {props.lang.chiudi}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
