const menuEN = {
    "pizza":[
        {
            "id": "1",
            "nome": "Marinara",
            "ingredienti": "Tomato, Garlic",
            "prezzo": 3.80,
            "allergie": [1]
        },
        {
            "id": "2",
            "nome": "Margherita",
            "ingredienti": "Tomato, Mozzarella",
            "prezzo": 5.50,
            "allergie": [1,7]
        },
		{
            "id": "3",
            "nome": "Funghi",
            "ingredienti": "Tomato, Mozzarella, Mushrooms",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "4",
            "nome": "Prosciutto",
            "ingredienti": "Tomato, Mozzarella, Ham",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "5",
            "nome": "Prosciutto Funghi",
            "ingredienti": "Tomato, Mozzarella, Ham, Mushrooms",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "6",
            "nome": "Capricciosa",
            "ingredienti": "Tomato, Mozzarella, Ham, Mushrooms, Artichokes",
            "prezzo": 8.50,
            "allergie": [1,7]
        },
		{
            "id": "7",
            "nome": "Quattro Stagioni",
            "ingredienti": "Tomato, Mozzarella, Ham, Mushrooms, Artichokes",
            "prezzo": 8.50,
            "allergie": [1,7]
        },
		{
            "id": "8",
            "nome": "Pugliese",
            "ingredienti": "Tomato, Mozzarella, Onion",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "9",
            "nome": "Salamino Piccante",
            "ingredienti": "Tomato, Mozzarella, Hot Salami",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "10",
            "nome": "Romana",
            "ingredienti": "Tomato, Mozzarella, Anchovies",
            "prezzo": 7.00,
            "allergie": [1,4,7]
        },
		{
            "id": "11",
            "nome": "Napoletana",
            "ingredienti": "Tomato, Mozzarella, Anchovies, Capers",
            "prezzo": 8.50,
            "allergie": [1,4,7]
        },
		{
            "id": "12",
            "nome": "Siciliana",
            "ingredienti": "Tomato, Mozzarella, Anchovies, Capers, Hot Salami, Green Olives",
            "prezzo": 8.50,
            "allergie": [1,4,7]
        },
		{
            "id": "13",
            "nome": "Wiennese",
            "ingredienti": "Tomato, Mozzarella, Wurstel",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "14",
            "nome": "Tonno e Cipolla",
            "ingredienti": "Tomato, Mozzarella, Tuna Fish, Onion",
            "prezzo": 8.00,
            "allergie": [1,4,7]
        },
		{
            "id": "15",
            "nome": "Zingara",
            "ingredienti": "Tomato, Mozzarella, Sweet Peppers",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "16",
            "nome": "Calzone",
            "ingredienti": "Tomato, Mozzarella, Ham, Mushrooms",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "17",
            "nome": "Calzone Filante",
            "ingredienti": "Tomato, Mozzarella, Spicy Gorgonzola Cheese, Mature Cheese",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "18",
            "nome": "Cynar",
            "ingredienti": "Tomato, Mozzarella, Artichokes",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "19",
            "nome": "Pizza della Casa",
            "ingredienti": "Tomato, Mozzarella, Ham, Mushrooms, Artichokes, Hot Salami, Wurstel, Green Olives, Egg",
            "prezzo": 9.50,
            "allergie": [1,3,7]
        },
		{
            "id": "20",
            "nome": "Quattro Formaggi",
            "ingredienti": "Tomato, Mozzarella, Parmesan Cheese, Spicy Gorgonzola Cheese",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "21",
            "nome": "Diavola",
            "ingredienti": "Tomato, Mozzarella, Hot Salami, Seeds of Hot Pepper",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "22",
            "nome": "Verdure",
            "ingredienti": "Tomato, Mozzarella, Asparagus, Mushrooms, Eggplant, Sweet Peppers, Artichokes, Marrow, Spinach",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "23",
            "nome": "Alla Francese",
            "ingredienti": "Tomato, Mozzarella, Brie",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "24",
            "nome": "Delicata",
            "ingredienti": "Tomato, Mozzarella, Brie, Rocked Salad",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "25",
            "nome": "Sfiziosa",
            "ingredienti": "Tomato, Mozzarella, Brie, Speck",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "26",
            "nome": "Estiva",
            "ingredienti": "Tomato, Mozzarella, Rocked Salad, Corn",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "27",
            "nome": "Saporita",
            "ingredienti": "Tomato, Mozzarella, Boiled Potatoes, Sausages",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "28",
            "nome": "Lory",
            "ingredienti": "Tomato, Mozzarella, Sausages, Cherry Tomatoes",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "29",
            "nome": "Mattia",
            "ingredienti": "Tomato, Mozzarella, Hot Salami, Sausages",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "30",
            "nome": "Bismarck",
            "ingredienti": "Tomato, Mozzarella, Asparagus, Egg",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "31",
            "nome": "Spinaci e Asparagi",
            "ingredienti": "Tomato, Mozzarella, Spinach, Asparagus",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "32",
            "nome": "Spinaci",
            "ingredienti": "Tomato, Mozzarella, Spinach",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "33",
            "nome": "Asparagi",
            "ingredienti": "Tomato, Mozzarella, Asparagus",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "34",
            "nome": "Melanzane",
            "ingredienti": "Tomato, Mozzarella, Grilled Eggplant",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "35",
            "nome": "Tonno e Olive",
            "ingredienti": "Tomato, Mozzarella, Tuna Fish, Olives",
            "prezzo": 8.00,
            "allergie": [1,4,7]
        },
		{
            "id": "36",
            "nome": "Tonno e Peperoni",
            "ingredienti": "Tomato, Mozzarella, Tuna Fish, Sweet Peppers",
            "prezzo": 8.00,
            "allergie": [1,4,7]
        },
		{
            "id": "37",
            "nome": "Baga",
            "ingredienti": "Tomato, Mozzarella, Spicy Gorgonzola, Chicory",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "38",
            "nome": "Mirna",
            "ingredienti": "Tomato, Mozzarella, Rocked Salad, Parmesan Cheese, Cherry Tomatoes",
            "prezzo": 8.50,
            "allergie": [1,7]
        },
		{
            "id": "39",
            "nome": "Capitan Hook",
            "ingredienti": "Tomato, Mozzarella, Tuna Fish, Onion, Hot Salami",
            "prezzo": 9.00,
            "allergie": [1,4,7]
        },
		{
            "id": "40",
            "nome": "Pizza del Bosco",
            "ingredienti": "Tomato, Mozzarella, Mixed Mushrooms",
            "prezzo": 7.00,
            "allergie": [1,7]
        },
		{
            "id": "41",
            "nome": "Stark",
            "ingredienti": "Tomato, Matured Cheese",
            "prezzo": 7.50,
            "allergie": [1,7]
        },
		{
            "id": "42",
            "nome": "Suprema",
            "ingredienti": "Tomato, Mozzarella, Rocked Salad, Parmesan Cheese, Bresaola",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "43",
            "nome": "Pizza Letizia",
            "ingredienti": "Tomato, Mozzarella, Porcini Mushrooms, Brie, Speck, Peanuts",
            "prezzo": 9.00,
            "allergie": [1,7,8]
        },
		{
            "id": "44",
            "nome": "Far West",
            "ingredienti": "Tomato, Mozzarella, Beans, Onion, Bacon",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "45",
            "nome": "Kaiser",
            "ingredienti": "Tomato, Mozzarella, Hot Salami, Wurstel, Capers, Parmesan Cheese",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "46",
            "nome": "Memo",
            "ingredienti": "Tomato, Mozzarella, Peas, Tuna Fish",
            "prezzo": 8.00,
            "allergie": [1,4,7]
        },
		{
            "id": "47",
            "nome": "Tirolese",
            "ingredienti": "Tomato, Mozzarella, Wurstel, Cream, Speck",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "48",
            "nome": "Rustica",
            "ingredienti": "Tomato, Mozzarella, Beans, Onion, Sausages",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "49",
            "nome": "Kostlich",
            "ingredienti": "Tomato, Mozzarella, Spicy Gorgonzola Cheese, Porcini Mushrooms",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "50",
            "nome": "Porcheria",
            "ingredienti": "Tomato, Mozzarella, Hot Salami, Sausages, Bacon",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "51",
            "nome": "Micky",
            "ingredienti": "Tomato, Mozzarella, Asparagus, Hot Salami, Spicy Gorgonzola Cheese",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "52",
            "nome": "Broccoli",
            "ingredienti": "Tomato, Mozzarella, Sausages, Broccoli, Smoked Ricotta",
            "prezzo": 9.00,
            "allergie": [1,7],
            "print": "newPage"
        },
		{
            "id": "53",
            "nome": "Fiamma",
            "ingredienti": "Tomato, Mozzarella, Zucchini, Curry, Shrimps",
            "prezzo": 9.00,
            "allergie": [1,2,4,7]
        },
		{
            "id": "54",
            "nome": "Autunno",
            "ingredienti": "Tomato, Mozzarella, Chestnuts, Sausages, Smoked Ricotta",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "55",
            "nome": "Illuminata",
            "ingredienti": "Tomato, Mozzarella, Mascarpone, Hot Salami",
            "prezzo": 8.50,
            "allergie": [1,7]
        },
		{
            "id": "56",
            "nome": "Hawaii",
            "ingredienti": "Tomato, Mozzarella, Pineapple, Speck",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "57",
            "nome": "Edelweiss",
            "ingredienti": "Tomato, Mozzarella, Sausages, Smoked Ricotta",
            "prezzo": 8.00,
            "allergie": [1,7],
			"novita": true 
        },
		{
            "id": "58",
            "nome": "Salzach",
            "ingredienti": "Tomato, Mozzarella, Brie, Cherry Tomatoes, Leeks",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "59",
            "nome": "Notti Magiche",
            "ingredienti": "Tomato, Mozzarella, Smocked Fillet, Lettuce, Parmesan Cheese",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "60",
            "nome": "Fiori di Zucca",
            "ingredienti": "Tomato, Mozzarella, Brie, Courgette Flowers",
            "prezzo": 8.00,
            "allergie": [1,7]
        },
		{
            "id": "61",
            "nome": "Halloween",
            "ingredienti": "Tomato, Mozzarella, Pumpkin, Sausages, Smoked Ricotta",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "67",
            "nome": "Pizza Thomas",
            "ingredienti": "Tomato, Mozzarella, Scamorza, Potatoes, Sausages, Rosmarino",
            "prezzo": 9.00,
            "allergie": [1,7],
			"novita": true
        },
		{
            "id": "94",
            "nome": "Fossati Kyle",
            "ingredienti": "Tomato, Mozzarella, Grana, Gorgonzola piccante, Salsiccia, Smoked affumicata",
            "prezzo": 10.00,
            "allergie": [1,7]
        },
		{
            "id": "95",
            "nome": "Queen Mama",
            "ingredienti": "Tomato, Mozzarella, Grana Padano, Peppers, Potatoes",
            "prezzo": 9.00,
            "allergie": [1,7]
        },
		{
            "id": "96",
            "nome": "Joker",
            "ingredienti": "Mozzarella, Gorgonzola, Walnuts",
            "prezzo": 8.50,
            "allergie": [1,5,7]
        },
		{
            "id": "97",
            "nome": "Freddie",
            "ingredienti": "Tomato, Mozzarella, Smoked Salmon, Leek",
            "prezzo": 8.50,
            "allergie": [1,4,7]
        },
		{
            "id": "98",
            "nome": "Zlatah",
            "ingredienti": "Mozzarella, Zucchini, Smoked Salmon, Cream",
            "prezzo": 9.00,
            "allergie": [1,4,7]
        }
    ],
    "dessert":[
        {
            "id": "62",
            "nome": "Panna Cotta",
            "ingredienti": "With strawberry, caramel or kiwi sauce",
            "prezzo": 4.00,
            "allergie": [1,3,5,6,7,8]
        },
		{
            "id": "63",
            "nome": "Tartufi",
            "ingredienti": "With Coffee or Chocolate",
            "prezzo": 4.00,
            "allergie": [1,3,5,6,7,8]
        },
		{
            "id": "64",
            "nome": "Tartufo Affogato",
            "ingredienti": "With Coffee or Chocolate",
            "prezzo": 5.00,
            "allergie": [1,3,5,6,7,8]
        },
		{
            "id": "65",
            "nome": "Semifreddi",
            "ingredienti": "-",
            "prezzo": 4.00,
            "allergie": [1,3,5,6,7,8]
        },
		{
            "id": "66",
            "nome": "Meringata",
            "ingredienti": "-",
            "prezzo": 4.00,
            "allergie": [1,3,5,6,7,8]
        }
    ],
	"caffeeliquori":[
        {
            "id": "68",
            "nome": "Caffè",
            "ingredienti": "",
            "prezzo": 1.30,
            "allergie": []
        },
		{
            "id": "69",
            "nome": "Caffè Corretto",
            "ingredienti": "",
            "prezzo": 1.80,
            "allergie": []
        },
		{
            "id": "70",
            "nome": "Liquori nazionali",
            "ingredienti": "",
            "prezzo": 3.50,
            "allergie": []
        },
		{
            "id": "71",
            "nome": "Grappe aromatiche",
            "ingredienti": "",
            "prezzo": 3.50,
            "allergie": []
        },
		{
            "id": "72",
            "nome": "Grappa Prime Uve Nera",
            "ingredienti": "",
            "prezzo": 5.00,
            "allergie": []
        },
		{
            "id": "73",
            "nome": "Liquori esteri e Whisky",
            "ingredienti": "",
            "prezzo": "5.00 - 8.00",
            "allergie": []
        },
		{
            "id": "74",
            "nome": "Irish Caffè",
            "ingredienti": "",
            "prezzo": 6.00,
            "allergie": []
        },
		{
            "id": "75",
            "nome": "Sgroppino",
            "ingredienti": "",
            "prezzo": 5.50,
            "allergie": []
        }
    ],
	"bibite":[
        {
            "id": "76",
            "nome": "Fanta - CocaCola - Sprite",
            "ingredienti": "",
            "prezzo": 2.50,
            "allergie": []
        },
		{
            "id": "78",
            "nome": "The Freddo",
            "ingredienti": "Peach or Lemon",
            "prezzo": 2.50,
            "allergie": []
        },
		{
            "id": "79",
            "nome": "Succhi di Frutta",
            "ingredienti": "Pear, Peach, Apricot, Ace, Orange, Pineapple",
            "prezzo": 2.50,
            "allergie": []
        },
		{
            "id": "80",
            "nome": "Acqua minerale",
            "ingredienti": "Natural or Sparkling",
            "prezzo": 1.50,
            "allergie": []
        }
	],
	"aperitivi":[
        {
            "id": "81",
            "nome": "Gingerino, SanBitter, Campari",
            "ingredienti": "",
            "prezzo": 2.50,
            "allergie": []
        },
		{
            "id": "82",
            "nome": "Furlan",
            "ingredienti": "",
            "prezzo": 2.50,
            "allergie": []
        },
		{
            "id": "83",
            "nome": "Prosecco",
            "ingredienti": "Al calice",
            "prezzo": 2.50,
            "allergie": []
        }
	],
	"birreevini":[
        {
            "id": "84",
            "nome": "Birra Piccola",
            "ingredienti": "",
            "prezzo": 2.50,
            "allergie": []
        },
		{
            "id": "85",
            "nome": "Birra Media",
            "ingredienti": "",
            "prezzo": 4.50,
            "allergie": []
        },
		{
            "id": "86",
            "nome": "Stivale o Caraffa di Birra",
            "ingredienti": "",
            "prezzo": 15.00,
            "allergie": []
        },
		{
            "id": "87",
            "nome": "Tocai del Collio",
            "ingredienti": "1/4 lt",
            "prezzo": 4.00,
            "allergie": []
        },
		{
            "id": "88",
            "nome": "Tocai del Collio",
            "ingredienti": "1/2 lt",
            "prezzo": 5.00,
            "allergie": []
        },
		{
            "id": "89",
            "nome": "Tocai del Collio",
            "ingredienti": "Bottiglia",
            "prezzo": 8.50,
            "allergie": []
        },
		{
            "id": "90",
            "nome": "Merlot",
            "ingredienti": "1/4 lt",
            "prezzo": 4.00,
            "allergie": []
        },
		{
            "id": "91",
            "nome": "Merlot",
            "ingredienti": "1/2 lt",
            "prezzo": 5.00,
            "allergie": []
        },
		{
            "id": "92",
            "nome": "Merlot",
            "ingredienti": "Bottiglia",
            "prezzo": 8.50,
            "allergie": []
        },
		{
            "id": "93",
            "nome": "Prosecco",
            "ingredienti": "Bottiglia",
            "prezzo": 15.00,
            "allergie": []
        }
	]
};

export default menuEN;
