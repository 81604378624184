import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import TranslateIcon from '@material-ui/icons/Translate';
import it from './lang/it.js';
import en from './lang/en.js';
import menuIT from './menu/menuIT.js';
import menuEN from './menu/menuEN.js';
import ItemMenu from './ItemMenu.js';
import logo from "./img/logo.png";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import uno from "./img/1.png";
import due from "./img/2.png";
import tre from "./img/3.png";
import quattro from "./img/4.png";
import cinque from "./img/5.png";
import sei from "./img/6.png";
import sette from "./img/7.png";
import otto from "./img/8.png";
import nove from "./img/9.png";
import dieci from "./img/10.png";
import undici from "./img/11.png";
import dodici from "./img/12.png";
import tredici from "./img/13.png";
import quattordici from "./img/14.png";
import logoata from "./img/logoata.png";
import logohey from "./img/logohey.png";
import uuidv1 from  'uuid/v1';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import LocalPizzaIcon from '@material-ui/icons/LocalPizza';
import CakeIcon from '@material-ui/icons/Cake';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import PhoneIcon from '@material-ui/icons/Phone';
import ReactGA from 'react-ga';
import './App.css';

const trackingId = "UA-116536689-10"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      marginRight: '50px'
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: '16px',
    transition: theme.transitions.create('width'),
    width: '100%'
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  menuList: {
    borderBottom: 'solid 1px #B08D6D',
    borderTop: 'solid 1px #B08D6D'
  },
  allergeni:{
    height: "0px",
    overflow: "hidden",
    padding: "0px",
    display: "block"
  },
  tapElement:{
    textAlign: "center"
  },
  allergeniExp: {
    background: "rgb(232,215,104)"
  },
  iconBtnSearch: {
    color: "white"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    color: "white"
  },
  searchPaper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    background: "rgba(255,255,255,0.3)",
    color: "white"
  },
  divider: {
    height: 28,
    margin: 4
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
}));

const scrollToRef = (ref) => window.scrollTo({
  top: ref.current.offsetTop-60,
  left: 0,
  behavior: 'smooth'
});

function App() {
  const [lang, setLang] = React.useState(localStorage.getItem("lang") && localStorage.getItem("lang") === "en" ? en : it);
  const[typeLang, setTypeLang] = React.useState(localStorage.getItem("lang") ? localStorage.getItem("lang") : "it");
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [menu, setMenu] = React.useState(localStorage.getItem("lang") && localStorage.getItem("lang") === "en" ? menuEN : menuIT);

  const pizzeRef = React.createRef();
  const dessertRef = React.createRef();
  const caffeeliquoriRef = React.createRef();
  const bibiteRef = React.createRef();
  const aperitiviRef = React.createRef();
  const birreeviniRef = React.createRef();

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const [pizza, setPizza] = React.useState(localStorage.getItem("lang") && localStorage.getItem("lang") === "en" ? menuEN.pizza : menuIT.pizza);
  const [dessert, setDessert] = React.useState(localStorage.getItem("lang") && localStorage.getItem("lang") === "en" ? menuEN.dessert : menuIT.dessert);
  const [caffeeliquori, setCaffeeliquori] = React.useState(localStorage.getItem("lang") && localStorage.getItem("lang") === "en" ? menuEN.caffeeliquori : menuIT.caffeeliquori);
  const [bibite, setBibite] = React.useState(localStorage.getItem("lang") && localStorage.getItem("lang") === "en" ? menuEN.bibite : menuIT.bibite);
  const [aperitivi, setAperitivi] = React.useState(localStorage.getItem("lang") && localStorage.getItem("lang") === "en" ? menuEN.aperitivi : menuIT.aperitivi);
  const [birreevini, setBirreevini] = React.useState(localStorage.getItem("lang") && localStorage.getItem("lang") === "en" ? menuEN.birreevini : menuIT.birreevini);

  const clearSearchHandler = () => {
    document.getElementById("searchField").value = "";
    searchHandler();
  }

  const handleLangChange = (event) => {
    setMobileMoreAnchorEl(null);
    if(typeLang === "it"){
      setLang(en);
      setTypeLang("en");
      localStorage.setItem("lang", "en");
      setMenu(menuEN);
      setPizza(menuEN.pizza);
      setDessert(menuEN.dessert);
      setCaffeeliquori(menuEN.caffeeliquori);
      setBibite(menuEN.bibite);
      setAperitivi(menuEN.aperitivi);
      setBirreevini(menuEN.birreevini);
    } else {
      setLang(it);
      setTypeLang("it");
      localStorage.setItem("lang", "it");
      setMenu(menuIT);
      setPizza(menuIT.pizza);
      setDessert(menuIT.dessert);
      setCaffeeliquori(menuIT.caffeeliquori);
      setBibite(menuIT.bibite);
      setAperitivi(menuIT.aperitivi);
      setBirreevini(menuIT.birreevini);
    }
  };

  const searchHandler = () => {
    scrollToRef(pizzeRef);
    var searchQuery = document.getElementById("searchField").value.toLowerCase();
    var pizzaSearch = menu.pizza.filter((el) => {
      var searchNome = el.nome.toLowerCase();
      var searchIngredienti = el.ingredienti.toLowerCase();
      return searchNome.indexOf(searchQuery) !== -1 || searchIngredienti.indexOf(searchQuery) !== -1;
    })
    var dessertSearch = menu.dessert.filter((el) => {
      var searchNome = el.nome.toLowerCase();
      var searchIngredienti = el.ingredienti.toLowerCase();
      return searchNome.indexOf(searchQuery) !== -1 || searchIngredienti.indexOf(searchQuery) !== -1;
    })
    var caffeeliquoriSearch = menu.caffeeliquori.filter((el) => {
      var searchNome = el.nome.toLowerCase();
      var searchIngredienti = el.ingredienti.toLowerCase();
      return searchNome.indexOf(searchQuery) !== -1 || searchIngredienti.indexOf(searchQuery) !== -1;
    })
    var bibiteSearch = menu.bibite.filter((el) => {
      var searchNome = el.nome.toLowerCase();
      var searchIngredienti = el.ingredienti.toLowerCase();
      return searchNome.indexOf(searchQuery) !== -1 || searchIngredienti.indexOf(searchQuery) !== -1;
    })
    var aperitiviSearch = menu.aperitivi.filter((el) => {
      var searchNome = el.nome.toLowerCase();
      var searchIngredienti = el.ingredienti.toLowerCase();
      return searchNome.indexOf(searchQuery) !== -1 || searchIngredienti.indexOf(searchQuery) !== -1;
    })
    var birreeviniSearch = menu.birreevini.filter((el) => {
      var searchNome = el.nome.toLowerCase();
      var searchIngredienti = el.ingredienti.toLowerCase();
      return searchNome.indexOf(searchQuery) !== -1 || searchIngredienti.indexOf(searchQuery) !== -1;
    })
    setPizza(pizzaSearch);
    setDessert(dessertSearch);
    setCaffeeliquori(caffeeliquoriSearch);
    setBibite(bibiteSearch);
    setAperitivi(aperitiviSearch);
    setBirreevini(birreeviniSearch);
  }

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSpeedClick = (ref) => {
    if(ref === 1 && pizzeRef.current != null){
      scrollToRef(pizzeRef);
    } else if(ref === 2 && dessertRef.current != null){
      scrollToRef(dessertRef);
    } else if(ref === 3 && caffeeliquoriRef.current != null){
      scrollToRef(caffeeliquoriRef);
    } else if(ref === 4 && bibiteRef.current != null){
      scrollToRef(bibiteRef);
    } else if(ref === 5 && aperitiviRef.current != null){
      scrollToRef(aperitiviRef);
    } else if(ref === 6 && birreeviniRef.current != null){
      scrollToRef(birreeviniRef);
    }
    setOpen(false);
  }

  const menuId = 'primary-search-account-menu';

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem  onClick={handleLangChange}>
        <IconButton aria-label="{lang.lang}" color="inherit">
          <Badge color="secondary">
            <TranslateIcon />
          </Badge>
        </IconButton>
        <p>{lang.lang}</p>
      </MenuItem>
    </Menu>
  );

  const pizzeL = (
    <div>
      <Typography className="title_cat" variant="h5" ref={pizzeRef}>
        {lang.pizze}
      </Typography>
      <List className={classes.root}>
        {pizza.map((item) => {
          return(
            <ItemMenu 
              lang={lang}
              categoria={lang.pizze}
              id={item.id}
              nome={item.nome}
              ingredienti={item.ingredienti}
              allergie={item.allergie}
              prezzo={item.prezzo}
              infoTitle={lang.ingredienti}
              print={item.print}
              key = {uuidv1()}
            />
          );
        })}
      </List>
    </div>
  );

  const dessertL = (
    <div>
      <Typography className="title_cat" variant="h5" ref={dessertRef}>
        {lang.dessert}
      </Typography>
      <List className={classes.root}>
        {dessert.map((item) => {
          return(
            <ItemMenu 
              lang={lang}
              categoria={lang.dessert}
              id={item.id}
              nome={item.nome}
              ingredienti={item.ingredienti}
              allergie={item.allergie}
              prezzo={item.prezzo}
              infoTitle={lang.info}
              print={item.print}
              key = {uuidv1()}
            />
          );
        })}
      </List>
    </div>
  );

  const caffeeliquoriL = (
    <div>
      <Typography className="title_cat" variant="h5" ref={caffeeliquoriRef}>
        {lang.caffeeliquori}
      </Typography>
      <List className={classes.root}>
        {caffeeliquori.map((item) => {
          return(
            <ItemMenu 
              lang={lang}
              categoria={lang.caffeeliquori}
              id={item.id}
              nome={item.nome}
              ingredienti={item.ingredienti}
              allergie={item.allergie}
              prezzo={item.prezzo}
              infoTitle={lang.info}
              print={item.print}
              key = {uuidv1()}
            />
          );
        })}
      </List>
    </div>
  );

  const bibiteL = (
    <div>
      <Typography className="title_cat" variant="h5" ref={bibiteRef}>
        {lang.bibite}
      </Typography>
      <List className={classes.root}>
        {bibite.map((item) => {
          return(
            <ItemMenu 
              lang={lang}
              categoria={lang.bibite}
              id={item.id}
              nome={item.nome}
              ingredienti={item.ingredienti}
              allergie={item.allergie}
              prezzo={item.prezzo}
              infoTitle={lang.info}
              print={item.print}
              key = {uuidv1()}
            />
          );
        })}
      </List>
    </div>
  );

  const aperitiviL = (
    <div>
      <Typography className="title_cat" variant="h5" ref={aperitiviRef}>
        {lang.aperitivi}
      </Typography>
      <List className={classes.root}>
        {aperitivi.map((item) => {
          return(
            <ItemMenu 
              lang={lang}
              categoria={lang.aperitivi}
              id={item.id}
              nome={item.nome}
              ingredienti={item.ingredienti}
              print={item.print}
              allergie={item.allergie}
              prezzo={item.prezzo}
              infoTitle={lang.info}
              key = {uuidv1()}
            />
          );
        })}
      </List>
    </div>
  );

  const birreeviniL = (
    <div>
      <Typography className="title_cat print_newPage" variant="h5" ref={birreeviniRef}>
        {lang.birreevini}
      </Typography>
      <List className={classes.root}>
        {birreevini.map((item) => {
          return(
            <ItemMenu 
              lang={lang}
              categoria={lang.birreevini}
              id={item.id}
              nome={item.nome}
              ingredienti={item.ingredienti}
              allergie={item.allergie}
              prezzo={item.prezzo}
              infoTitle={lang.info}
              print={item.print}
              key = {uuidv1()}
            />
          );
        })}
      </List>
    </div>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed">
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            Albergo Pizzeria "Al Cervo"
          </Typography>
          <Paper component="div" className={classes.searchPaper}>
            <InputBase
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              id="searchField"
              placeholder={lang.cerca}
              inputProps={{ 'aria-label': 'search' }}
            />
            <IconButton className={classes.iconBtnSearch} aria-label="search" onClick={searchHandler}>
              <SearchIcon />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton color="primary" className={classes.iconButton} aria-label="directions" onClick={clearSearchHandler}>
              <ClearIcon />
            </IconButton>
          </Paper>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleLangChange}
              color="inherit"
            >
              {lang.lang}
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleLangChange}
              color="inherit"
            >
              {lang.lang}
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      <Typography variant="h1">
        Albergo Pizzeria "Al Cervo"
      </Typography>
      <div className="wrapLogo"><img className="logo" src={logo} alt="Logo"/></div>
      <p className={classes.tapElement}>{lang.cliccasuitem}</p>
      <ExpansionPanel className={classes.allergeniExp}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{lang.textInfoAllergeni}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            <Typography align="center">{lang.textLeggeAllergeni}</Typography>
            <br/>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  <TableRow key="1">
                    <TableCell align="right"><img className="iconAllerg" src={uno} alt={lang.infoAllergie[0]}/></TableCell>
                    <TableCell align="left">{lang.infoAllergie[0]}</TableCell>
                  </TableRow>
                  <TableRow key="2">
                    <TableCell align="right"><img className="iconAllerg" src={due} alt={lang.infoAllergie[1]}/></TableCell>
                    <TableCell align="left">{lang.infoAllergie[1]}</TableCell>
                  </TableRow>
                  <TableRow key="3">
                    <TableCell align="right"><img className="iconAllerg" src={tre} alt={lang.infoAllergie[2]}/></TableCell>
                    <TableCell align="left">{lang.infoAllergie[2]}</TableCell>
                  </TableRow>
                  <TableRow key="4">
                    <TableCell align="right"><img className="iconAllerg" src={quattro} alt={lang.infoAllergie[3]}/></TableCell>
                    <TableCell align="left">{lang.infoAllergie[3]}</TableCell>
                  </TableRow>
                  <TableRow key="5">
                    <TableCell align="right"><img className="iconAllerg" src={cinque} alt={lang.infoAllergie[4]}/></TableCell>
                    <TableCell align="left">{lang.infoAllergie[4]}</TableCell>
                  </TableRow>
                  <TableRow key="6">
                    <TableCell align="right"><img className="iconAllerg" src={sei} alt={lang.infoAllergie[5]}/></TableCell>
                    <TableCell align="left">{lang.infoAllergie[5]}</TableCell>
                  </TableRow>
                  <TableRow key="7">
                    <TableCell align="right"><img className="iconAllerg" src={sette} alt={lang.infoAllergie[6]}/></TableCell>
                    <TableCell align="left">{lang.infoAllergie[6]}</TableCell>
                  </TableRow>
                  <TableRow key="8">
                    <TableCell align="right"><img className="iconAllerg" src={otto} alt={lang.infoAllergie[7]}/></TableCell>
                    <TableCell align="left">{lang.infoAllergie[7]}</TableCell>
                  </TableRow>
                  <TableRow key="9">
                    <TableCell align="right"><img className="iconAllerg" src={nove} alt={lang.infoAllergie[8]}/></TableCell>
                    <TableCell align="left">{lang.infoAllergie[8]}</TableCell>
                  </TableRow>
                  <TableRow key="10">
                    <TableCell align="right"><img className="iconAllerg" src={dieci} alt={lang.infoAllergie[9]}/></TableCell>
                    <TableCell align="left">{lang.infoAllergie[9]}</TableCell>
                  </TableRow>
                  <TableRow key="11">
                    <TableCell align="right"><img className="iconAllerg" src={undici} alt={lang.infoAllergie[10]}/></TableCell>
                    <TableCell align="left">{lang.infoAllergie[10]}</TableCell>
                  </TableRow>
                  <TableRow key="12">
                    <TableCell align="right"><img className="iconAllerg" src={dodici} alt={lang.infoAllergie[11]}/></TableCell>
                    <TableCell align="left">{lang.infoAllergie[11]}</TableCell>
                  </TableRow>
                  <TableRow key="13">
                    <TableCell align="right"><img className="iconAllerg" src={tredici} alt={lang.infoAllergie[12]}/></TableCell>
                    <TableCell align="left">{lang.infoAllergie[12]}</TableCell>
                  </TableRow>
                  <TableRow key="14">
                    <TableCell align="right"><img className="iconAllerg" src={quattordici} alt={lang.infoAllergie[13]}/></TableCell>
                    <TableCell align="left">{lang.infoAllergie[13]}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <br/>
            <Typography align="center">{lang.textResponsabileAllergeni}</Typography>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {pizzeL}
      {dessertL}
      {caffeeliquoriL}
      {bibiteL}
      {aperitiviL}
      {birreeviniL}
      <footer>
        <Typography>< PhoneIcon /> <a href="tel:042779345">0427 79345</a></Typography>
        <Typography>{lang.closingDay}</Typography>
        <Typography  className="print_display_none">Designed by</Typography>
        <a href="https://www.ata-informatica.com" target="_blank" rel="noopener noreferrer"><img src={logoata} alt="Logo ATA-Informatica" className="print_display_none"/></a><br/><br/>
        <a href="https://www.heytech.it" target="_blank" rel="noopener noreferrer"><img src={logohey} alt="Logo Hey tech di Mirco Cossutta" className="print_display_none"/></a>
      </footer>

      <SpeedDial
        ariaLabel="Menu jump"
        className={classes.speedDial}
        icon={<SpeedDialIcon icon={<MenuBookIcon />} openIcon={<RestaurantMenuIcon/>}/>}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        <SpeedDialAction
          key={lang.pizze}
          icon={<LocalPizzaIcon />}
          tooltipTitle={lang.pizze}
          tooltipOpen
          onClick={() => handleSpeedClick(1)}
        />
        <SpeedDialAction
          key={lang.dessert}
          icon={<CakeIcon />}
          tooltipTitle={lang.dessert}
          tooltipOpen
          onClick={() => handleSpeedClick(2)}
        />
        <SpeedDialAction
          key={lang.caffeeliquoriAttaccato}
          icon={<LocalCafeIcon />}
          tooltipTitle={lang.caffeeliquoriAttaccato}
          tooltipOpen
          onClick={() => handleSpeedClick(3)}
        />
        <SpeedDialAction
          key={lang.bibite}
          icon={<LocalDrinkIcon />}
          tooltipTitle={lang.bibite}
          tooltipOpen
          onClick={() => handleSpeedClick(4)}
        />
        <SpeedDialAction
          key={lang.aperitivi}
          icon={<LocalBarIcon />}
          tooltipTitle={lang.aperitivi}
          tooltipOpen
          onClick={() => handleSpeedClick(5)}
        />
        <SpeedDialAction
          key={lang.birreeviniAttaccato}
          icon={<LocalBarIcon />}
          tooltipTitle={lang.birreeviniAttaccato}
          tooltipOpen
          onClick={() => handleSpeedClick(6)}
        />
      </SpeedDial>
    </div>
  );
}

export default App;
