const it = {};
it.cerca = 'Cerca...';
it.lang = 'EN';
it.pizze = 'Pizze';
it.dessert = "Dessert";
it.caffeeliquori = "Caffè & Liquori";
it.bibite = "Bibite";
it.aperitivi = "Aperitivi";
it.birreevini = "Birre & Vini";
it.birreeviniAttaccato = "Birre&Vini";
it.caffeeliquoriAttaccato = "Caffè&Liquori";
it.infoAllergie = [
    "Cereali contenenti glutine e prodotti derivati (grano, segale, orzo, avena, farro, kamut)",
    "Crostacei e prodotti a base di crostacei",
    "Uova e prodotti a base di uova",
    "Pesce e prodotti a base di pesce",
    "Arachidi e prodotti a base di arachidi",
    "Soia e prodotti a base di soia",
    "Latte e prodotti a base di latte",
    "Frutta a guscio e loro prodotti (mandorle, nocciole, noci, noci acagiù, di peccai, del Brasile, pistacchi, noci macadamia)",
    "Sedano e prodotti a base di sedano",
    "Senape e prodotti a base di senape",
    "Semi di sesamo e prodotti a base d isesamo",
    "Solfiti in concentrazione superiori a 10mg/Kg",
    "Lupini e prodotti a base di lupini",
    "Molluschi e prodotti a base di molluschi"
];
it.allergeni = "Allergeni";
it.chiudi = "Chiudi";
it.ingredienti = "Ingredienti";
it.info = "Info";
it.cliccasuitem = "Tocca un elemento per maggiori informazioni.";
it.textInfoAllergeni = "Informazioni su allergeni o loro derivati";
it.textLeggeAllergeni = 'Elenco degli ingredienti allergenici utilizzati in questo esercizio e presenti nell\'allegato II del Reg. UE n° 1169/2011 – "Sostanze o prodotti che provocano allergie o intolleranze"';
it.textResponsabileAllergeni = "Un nostro Responsabile incaricato è a vostra disposizione per fornire ogni supporto o informazione aggiuntiva, anche mediante l'esibizione di idonea documentazione, ricettari, etichette originali delle materie prime.";
it.closingDay = "Turno di chiusura: lunedì e martedì"

export default it;